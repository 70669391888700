<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="search-block">
            <div class="popcontext">
              <ul class="tab">
                <li style="height: 60px;">
                  <b style="font-size:16px;">旅客服务</b>
                </li>
                <li
                  rel="hgroup-booking"
                  @click="selectPannel(item.keyWord,item.cid)"
                  :class="activeIndex==item.keyWord?'active':''"
                  id="li_dynamic"
                  v-for="item in keysGroup"
                  v-bind:key="item.keyWord"
                >
                  <span>{{item.keyWord}}</span>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="right_pannel" style="background: none; border: 0px;">
            <div style="width:900px;padding: 10px;">
              <div class="news-block-body">
                <ul>
                  <li v-for="item in passengerServiceNewsItems" v-bind:key="item.id">
                    <a :href="'/doc/detail/'+item.id">
                      <span>{{item.newsTitle}}</span>
                      <span>{{item.createAt | fromNow}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <el-pagination
              style="text-align:right;"
              hide-on-single-page="false"
              @current-change="handleCurrentChange"
              :current-page.sync="pageInfo.page"
              :page-size="pageInfo.itemsPerPage"
              layout="total, prev, pager, next"
              :total="pageInfo.items">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import noticeLeft from "../../components/NoticeLeft";
import {
  fetchAirports,
  getOneByAliasCode,
  pssgroup,
  getNewsTopN,
  newslist
} from "@/api/newsapi";
export default {
  data() {
    return {
      activeIndex: "全部",
      loginType: "AIRPORT",
      input: "",
      title: "",
      keysGroup: [],
      newsContent: "",
      dynamicFilter: {
        dynamicType: "",
        deptAirportCode: "",
        deptAirport: "",
        arrAirport: "",
        arrAirportCode: "",
        deptDate: "",
        flightNo: ""
      },
      restaurants: [],
      tableData: [],
      passengerServiceNewsItems: [],
      pageInfo:{
        items:100,
        itemsPerPage:20,
        page:1,
        pages:5
      },
      curCid: 0,
      curPannelId: 0
    };
  },
  mounted: function() {
    this.loadPssGroupData();
  },
  filters: {
    fromNow(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    mathInt(amount) {
      return Math.floor(amount);
    }
  },
  methods: {
    selectPannel(pannelId, cid) {
      this.activeIndex = pannelId;
      if (pannelId == "全部") {
        cid = this.keysGroup[0].cid;
      }
      this.curPannelId = pannelId;
      this.curCid = cid;
      this.loadNewslist(cid, pannelId == "全部" ? "" : pannelId);
    },
    loadNewslist: function(cid, key) {
      const {itemsPerPage, page} = this.pageInfo
      newslist({ keyword: key, cid: cid, pageSize: itemsPerPage, page }).then(res => {
        if (res.success) {
          this.passengerServiceNewsItems = res.values;
          this.pageInfo = res.pageInfo;
        }
      });
    },
    loadPssGroupData() {
      pssgroup({ aliasCode: "TCAT_PASSSERVICE" }).then(res => {
        if (res.success) {
          var tempGroup = res.data;
          var usefulGroup = [];
          tempGroup.forEach(item => {
            if (item.keyWord != "其它" && item.keyWord != "其他") {
              usefulGroup.push(item);
            }
          });
          // var cats = [
          //   { keyWord: "客票规则", cid: 0 },
          //   { keyWord: "行李须知", cid: 0 },
          //   { keyWord: "应急处置", cid: 0 },
          //   { keyWord: "服务协议", cid: 0 },
          //   { keyWord: "贴心服务", cid: 0 },
          //   { keyWord: "其他通知", cid: 0 }
          // ];
          usefulGroup.forEach(item => {
            item.cid = usefulGroup[0].cid;
            this.keysGroup.push(item);
          });
          const {cid} = this.$route.query
          this.activeIndex = this.keysGroup[cid].keyWord
          if(this.keysGroup.length>0){
            this.activeIndex=this.keysGroup[cid].keyWord;
            this.loadNewslist(this.keysGroup[cid].cid,this.keysGroup[cid].keyWord);
            this.curPannelId = this.keysGroup[cid].keyWord;
            this.curCid = this.keysGroup[cid].cid;
          }
          else{
            this.loadNewslist(this.keysGroup[0].cid, "");
            this.curPannelId = '';
            this.curCid = this.keysGroup[0].cid;
          }
          
        }
      });
    },
    changeTab(tab) {
      this.loginType = tab;
    },
    getAirports: function() {
      return fetchAirports();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.chineseName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0 ||
          restaurant.airportCode
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0
        );
      };
    },
    handleSelectDynamic(item) {
      this.dynamicFilter.deptAirport = item.chineseName;
      this.dynamicFilter.deptAirportCode = item.airportCode;
    },
    handleSelectDynamic2(item) {
      this.dynamicFilter.arrAirport = item.chineseName;
      this.dynamicFilter.arrAirportCode = item.airportCode;
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.loadNewslist(this.curCid, this.curPannelId == "全部" ? "" : this.curPannelId);
    }
  }
};
</script>
<style scoped>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.right_pannel {
  min-height: 500px;
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #ededed;
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 240px !important;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 45px;
  line-height: 45px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  text-align: center;
  /* transition: all 0.3s ease 0s;
      -webkit-transition: all 0.5s ease 0s; */
  cursor: pointer;
  /* margin-left: 10px; */
  /* -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      filter: alpha(Opacity=60);
      -moz-opacity: 0.6; */
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  /* border-top: 1px solid #d6003a; */
}

.popcontext .tab li:last-child {
  /* -webkit-border-bottom-left-radius: 5px;
      -moz-border-bottom-left-radius: 5px;
      border-bottom-left-radius: 5px; */
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  border-left: 4px solid #d6003a;
  background-color: #d70039 !important;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  /* -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px; */
  /* border-radius: 3px 0 0 3px; */
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  /* -webkit-border-radius: 0 5px 5px 0;
      -moz-border-radius: 0 5px 5px 0; */
  /* border-radius: 0 5px 5px 0; */
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.panel-header {
  border-bottom: 2px solid #e9e8e8;
  margin-top: 10px;
  width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 42px;

  text-align: center;
  cursor: pointer;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.filter {
  width: 100%;
  float: left;
}

.filter .el-input {
  margin-top: 10px;
}

.el-date-editor .el-input {
  width: 100%;
}

.filter-title {
  margin-top: 15px;
  font-size: 12px;
  color: #7f7e7c;
  font-weight: 700;
}

.filter-input {
  position: relative;
  padding-top: 10px;
}

.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}

.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.filter-panel-title {
  color: #9d9d9d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-select-dropdown__item {
  padding-left: 15px;
}

.separet {
  height: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0 9px;
  margin-top: 50px;
}

.search-btn {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  margin-left: 32px;
  background-color: #ff0505;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #f7214b;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}

.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 45px;
  line-height: 45px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.news-block-body ul {
  margin-top: 10px;
  padding: 10px 12px 10px 2px;
  /* background-color: #fafafa;
  border: 1px solid #f5f5f5; */
  border-radius: 2px;
}

.news-block-body ul li {
  border-bottom: 1px dotted #ededed;
}

.news-block-body ul li a {
  display: block;
  padding: 10px 0;
}

.news-block-body ul li:last-child {
  border-bottom: none;
}

.news-block-body ul li a span:first-child {
  float: left;
  width: 680px;
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.news-block-body .clearfix {
  display: block;
  height: 0;
  content: "";
  clear: both;
  visibility: hidden;
  overflow: hidden;
}
</style>
